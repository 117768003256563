//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis } from '@/components'
import { flowableInstanceCommentHistory, flowableInstanceTrace } from '@/api/modular/flowable/instanceManage'
import jQuery from 'jquery'
import { ajaxSuccess } from '../../../assets/flowable/js/displaymodel.js'
import 'font-awesome/css/font-awesome.min.css'

export default {
components: {
  Ellipsis
},
  data () {
    return {
      visible: false,
      confirmLoading: false,
      tableLoading: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '環節名稱',
          dataIndex: 'name'
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '完成時間',
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' }
        },
        {
          title: '任務歷時',
          dataIndex: 'duration'
        },
        {
          title: '操作人',
          dataIndex: 'assigneeInfo',
          scopedSlots: { customRender: 'assigneeInfo' }
        },
        {
          title: '審批意見',
          dataIndex: 'comment',
          scopedSlots: { customRender: 'comment' }
        }
      ],
      loadData: [],
      legendLoading: false,
      legendShow: false,
      modalWidth: 900
    }
  },
  methods: {
    // 初始化方法
    tracking (id) {
      this.queryParam.id = id
      this.visible = true
      this.tableLoading = true
      this.legendShow = true
      this.flowableInstanceTrace()
      flowableInstanceCommentHistory(this.queryParam).then((res) => {
        this.tableLoading = false
        this.loadData = res.data
      })
      this.modalWidth = 900
      // this.getBrowserWidth()
    },
    flowableInstanceTrace () {
      this.legendLoading = true
      flowableInstanceTrace(this.queryParam).then((res) => {
        this.legendLoading = false
        this.legendShow = false
        if (res.success) {
          ajaxSuccess(res.data)
        } else {
          this.$message.error('圖形信息：' + res.message)
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.loadData = []
      document.getElementById('bpmnModel').innerHTML = ''
      jQuery('#legend').hide()
      jQuery('#bpmnModel').hide()
    },

    getBrowserWidth () {
      if ((document.body.clientWidth) > 1000) {
        this.modalWidth = (document.body.clientWidth) * 0.8
      }
    }
  }
}
